import { useState } from "react";
import {
  TextField,
  Grid,
  Typography,
  MenuItem,
  Button,
  styled,
  Checkbox,
  FormControlLabel,
  Alert,
  AlertTitle,
  Link,
  CircularProgress,
} from "@mui/material";
import { MuiTelInput, MuiTelInputInfo, matchIsValidTel } from "mui-tel-input";
import { Trans, useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";

import config from "../../../config/config.json";
import { useNavigate } from "react-router-dom";

const ContactFormWrapper = styled("div")({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

interface ContactFormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  company: string;
  inquiry: string | number;
  details: string;
  consent?: boolean;
}

const ContactForm = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [stage, setStage] = useState<number>(0);
  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      company: "",
      inquiry: 0,
      details: "",
      consent: false,
    } as ContactFormData,
    validationSchema: yup.object().shape({
      firstName: yup.string().required(t("contact.form.firstName.error")),
      lastName: yup.string().required(t("contact.form.lastName.error")),
      email: yup
        .string()
        .email(t("contact.form.email.error.valid"))
        .required(t("contact.form.email.error.required")),
      phoneNumber: yup
        .string()
        .test(
          "phoneNumber",
          t("contact.form.phoneNumber.error.valid"),
          (value) => matchIsValidTel(value as string)
        )
        .required(t("contact.form.phoneNumber.error.required"))
        .typeError(t("contact.form.phoneNumber.error.valid")),
      company: yup.string().required(t("contact.form.company.error")),
      inquiry: yup.number().required(t("contact.form.inquiry.error")),
      details: yup.string(),
      consent: yup
        .boolean()
        .required(t("contact.form.consent.error"))
        .oneOf([true]),
    }),
    onSubmit: async (values) => {
      try {
        setStage(1);
        const data = {
          ...values,
          consent: undefined,
          inquiry: (
            t("contact.form.inquiry.options", {
              returnObjects: true,
            }) as string[]
          )[values.inquiry as number],
        } as ContactFormData;
        await fetch(config.MailjetURL, {
          mode: "no-cors",
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ...data,
          }),
        });
      } catch {
        setError("An error has occured! Please try again.");
      } finally {
        setStage(2);
      }
    },
  });

  const handlePhoneNumberChange = (value: string, _info: MuiTelInputInfo) => {
    formik.setFieldValue("phoneNumber", value);
  };

  switch (stage) {
    case 0:
      return (
        <ContactFormWrapper>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={12} sm={6} xs={12}>
                <TextField
                  fullWidth
                  id="firstName"
                  name="firstName"
                  label={t("contact.form.firstName.label")}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </Grid>
              <Grid item lg={6} md={12} sm={6} xs={12}>
                <TextField
                  fullWidth
                  id="lastName"
                  name="lastName"
                  label={t("contact.form.lastName.label")}
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
              <Grid item lg={6} md={12} sm={6} xs={12}>
                <TextField
                  fullWidth
                  id="company"
                  name="company"
                  label={t("contact.form.company.label")}
                  value={formik.values.company}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.company && Boolean(formik.errors.company)
                  }
                  helperText={formik.touched.company && formik.errors.company}
                />
              </Grid>
              <Grid item lg={6} md={12} sm={6} xs={12}>
                <MuiTelInput
                  fullWidth
                  id="phoneNumber"
                  name="phoneNumber"
                  label={t("contact.form.phoneNumber.label")}
                  value={formik.values.phoneNumber}
                  onChange={handlePhoneNumberChange}
                  forceCallingCode
                  focusOnSelectCountry
                  defaultCountry="RO"
                  langOfCountryName={i18n.language}
                  preferredCountries={["RO", "DE", "FR", "GB", "ES", "IT"]}
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label={t("contact.form.email.label")}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  id="inquiry"
                  name="inquiry"
                  label={t("contact.form.inquiry.label")}
                  value={formik.values.inquiry}
                  defaultValue={
                    (
                      t("contact.form.inquiry.options", {
                        returnObjects: true,
                      }) as string[]
                    )[0]
                  }
                  onChange={formik.handleChange}
                  error={
                    formik.touched.inquiry && Boolean(formik.errors.inquiry)
                  }
                  helperText={formik.touched.inquiry && formik.errors.inquiry}
                >
                  {(
                    t("contact.form.inquiry.options", {
                      returnObjects: true,
                    }) as string[]
                  ).map((value, index) => {
                    return (
                      <MenuItem key={index} value={index}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  maxRows={5}
                  id="details"
                  name="details"
                  label={t("contact.form.details.label")}
                  value={formik.values.details}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.details && Boolean(formik.errors.details)
                  }
                  helperText={formik.touched.details && formik.errors.details}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox color="primary" checked={formik.values.consent} />
                  }
                  id="consent"
                  name="consent"
                  label={
                    <Typography variant="caption" color="textSecondary">
                      <Trans
                        t={t}
                        i18nKey={"contact.form.consent.label"}
                        defaults={t("contact.form.consent.label")}
                        components={[
                          <Link onClick={() => navigate("/privacy")} />,
                        ]}
                      />
                    </Typography>
                  }
                  onChange={formik.handleChange}
                />
              </Grid>
              {formik.touched.consent && Boolean(formik.errors.consent) && (
                <Grid item xs={12}>
                  <Alert severity="error">
                    {t("contact.form.consent.error")}
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  {t("general.submit")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </ContactFormWrapper>
      );
    case 1:
      return <ContactFormWrapper>
        <CircularProgress color="primary" size="4rem"/>
      </ContactFormWrapper>;
    case 2:
      return (
        <ContactFormWrapper>
          {error === "" && (
            <>
              <Typography
                align="center"
                variant="h3"
                sx={{ typography: { sm: "h3", xs: "h4" } }}
                gutterBottom
              >
                {t("contact.form.completed.title")}
              </Typography>
              <Typography
                align="center"
                variant="subtitle1"
                sx={{ typography: { sm: "subtitle1", xs: "subtitle2" } }}
                gutterBottom
              >
                {t("contact.form.completed.subtitle")}
              </Typography>
            </>
          )}
          {error !== "" && (
            <Alert sx={{ width: "100%" }} severity="error" variant="filled">
              <AlertTitle>{t("contact.form.error.title")}</AlertTitle>
              {t("contact.form.error.subtitle")}
            </Alert>
          )}
        </ContactFormWrapper>
      );
    default:
      return <></>;
  }
};

export default ContactForm;
