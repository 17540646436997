import { Card, Typography, styled } from "@mui/material";
import { Variants, motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const InitialsSection = styled("div")(({ theme }) => ({
  padding: "5% 10% 15% 10%",
  maxWidth: "100vw",
  background: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
}));

const InitialsSectionList = styled(motion.div)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.up(800)]: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  [theme.breakpoints.down(800)]: {
    flexDirection: "column",
    justiftContent: "space-between",
  },
}));

const InitialsSectionListElement = styled(motion.div)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down(800)]: {
    flexDirection: "row",
    marginBottom: "2rem",
  },
}));

const InitialsSectionListElementLetter = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down(800)]: {
    fontSize: "12.5vw",
    marginRight: "2.5rem",
  },
  [theme.breakpoints.up(800)]: {
    fontSize: "7.5vw",
    marginBottom: "2rem",
  },
}));

const InitialsSectionListElementCard = styled(Card)(({ theme }) => ({
  width: "25vw",
  height: "27.5vw",
  padding: "7.5%",
  borderRadius: "1rem",
  [theme.breakpoints.down(800)]: {
    width: "clamp(200px, 70vw, 500px)",
    height: "70vw",
  },
}));

const InitialsSectionListElementCardTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up(800)]: {
    marginTop: "1rem",
    fontSize: "3vw",
  },
  [theme.breakpoints.down(800)]: {
    fontSize: "8vw",
  },
}));

const InitialsSectionListElementCardText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [theme.breakpoints.up(800)]: {
    fontSize: "2vw",
  },
  [theme.breakpoints.down(800)]: {
    fontSize: "5vw",
  },
}));

const Initials = () => {
  const { t } = useTranslation();

  const scrollVariants: Variants = {
    offscreen: {
      opacity: 0,
      scale: 0.8,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 1,
        ease: "easeOut",
      },
    },
  };

  return (
    <InitialsSection>
      <Typography
        component={motion.div}
        initial="offscreen"
        whileInView="onscreen"
        variants={scrollVariants}
        sx={{
          mb: 3,
          userSelect: "none",
          typography: { md: "h2", xs: "h3" },
        }}
        gutterBottom
      >
        {t("main.initials.title")}
      </Typography>
      <InitialsSectionList>
        {(
          t("main.initials.text", { returnObjects: true }) as Array<Object>
        ).map((value: any, index) => (
          <InitialsSectionListElement
            key={index}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              variants={scrollVariants}
            >
              <InitialsSectionListElementLetter
                sx={{
                  userSelect: "none",
                }}
                color="primary"
              >
                <b>{value.letter}</b>
              </InitialsSectionListElementLetter>
            </motion.div>

            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              variants={scrollVariants}
            >
              <InitialsSectionListElementCard raised key={index}>
                <InitialsSectionListElementCardTitle
                  sx={{
                    userSelect: "none",
                  }}
                  align="center"
                  gutterBottom
                >
                  {value.word}
                </InitialsSectionListElementCardTitle>
                <InitialsSectionListElementCardText
                  sx={{
                    userSelect: "none",
                  }}
                  align="center"
                >
                  {value.text}
                </InitialsSectionListElementCardText>
              </InitialsSectionListElementCard>
            </motion.div>
          </InitialsSectionListElement>
        ))}
      </InitialsSectionList>
    </InitialsSection>
  );
};

export default Initials;
