import {
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
  styled,
} from "@mui/material";
import { Variants, motion } from "framer-motion";
import ContactForm from "./ContactForm";
import { Trans, useTranslation } from "react-i18next";
import config from "../../../config/config.json";

const Main = styled("main")({
  overflow: "hidden",
  maxWidth: "100vw",
  padding: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const ContactSection = styled("div")(({ theme }) => ({
  width: "100%",
  minHeight: "calc(100vh - 244px)",
  padding: "5% 10%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    minHeight: "calc(100vh - 236px)",
  },
}));

const ContactSectionInfoCard = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const ContactPage = () => {
  const { t } = useTranslation();

  const scrollVariants: Variants = {
    offscreen: {
      scale: 0.8,
      opacity: 0,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  return (
    <Main>
      <ContactSection>
        <Grid container spacing={4}>
          <Grid item sm={12} md={6}>
            <ContactSectionInfoCard>
              <Typography
                component={motion.div}
                initial="offscreen"
                whileInView="onscreen"
                variants={scrollVariants}
                sx={{
                  userSelect: "none",
                  typography: { md: "h2", sm: "h3", xs: "h4" },
                }}
                gutterBottom
              >
                {t("contact.title")}
              </Typography>
              <Card
                raised
                component={motion.div}
                initial="offscreen"
                whileInView="onscreen"
                variants={scrollVariants}
              >
                <CardContent>
                  <Typography
                    sx={{
                      userSelect: "none",
                      typography: {
                        md: "h6",
                        sm: "subtitle1",
                        xs: "subtitle2",
                      },
                    }}
                  >
                    {t("contact.pageDescription")}
                    <Trans
                      t={t}
                      i18nKey={"contact.contactInfo"}
                      defaults={t("contact.contactInfo")}
                      values={{
                        phoneNumber: config.phoneNumber,
                        email: config.emailContact,
                      }}
                      components={[
                        <Link key="1" href={`tel:${config.phoneNumber}`} />,
                        <Link
                          key="2"
                          href={`mailto:${config.emailContact}?Subject=Contact`}
                        />,
                      ]}
                    />
                  </Typography>
                </CardContent>
              </Card>
            </ContactSectionInfoCard>
          </Grid>
          <Grid item sm={12} md={6}>
            <Card
              raised
              component={motion.div}
              initial="offscreen"
              whileInView="onscreen"
              variants={scrollVariants}
              style={{
                padding: "clamp(1rem, 7.5%, 5rem)",
                height: "100%",
                width: "100%",
              }}
            >
              <ContactForm />
            </Card>
          </Grid>
        </Grid>
      </ContactSection>
    </Main>
  );
};

export default ContactPage;
