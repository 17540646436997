import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import config from "../../config/config.json";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import Logo from "../../resources/logo.svg";
import { useTranslation } from "react-i18next";

const AppFooter = styled(AppBar)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: "0 clamp(20px, 10%, 500px)",
}));

const Spacer = styled("span")({
  flex: "1 1 auto",
});

const FooterButton = styled(Button)(({ theme }) => ({
  // whiteSpace: "nowrap",
}));

const FooterLogo = styled("img")(({ theme }) => ({
  marginRight: "1rem",
  height: "3rem",
}));

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <AppFooter position="sticky">
      <Toolbar style={{ marginTop: "1rem" }} disableGutters>
        <FooterLogo src={Logo} alt="Logo"></FooterLogo>
        <Typography
          variant="h5"
          color="textPrimary"
          noWrap
          sx={{ userSelect: "none" }}
        >
          AEL Software
        </Typography>
      </Toolbar>
      <Toolbar variant="dense" disableGutters>
        <IconButton
          sx={{ marginLeft: "-0.75rem" }}
          size="large"
          onClick={() => {
            window.location.href = config.InstagramURL;
          }}
        >
          <InstagramIcon fontSize="inherit" />
        </IconButton>
        <IconButton
          sx={{ marginRight: "1.5rem" }}
          size="large"
          onClick={() => {
            window.location.href = config.LinkedInURL;
          }}
        >
          <LinkedInIcon fontSize="inherit" />
        </IconButton>
        <Spacer />
        <FooterButton
          variant="outlined"
          onClick={() => {
            navigate("/privacy");
          }}
        >
          <Typography variant="caption" color="textPrimary">
            {t("privacy.title")}
          </Typography>
        </FooterButton>
      </Toolbar>
      <Toolbar variant="dense" disableGutters>
        <Typography
          variant="caption"
          align="center"
          color="textPrimary"
          noWrap
          sx={{ mr: 2, userSelect: "none" }}
        >
          &copy; {new Date().getFullYear()} AEL Software {t("general.rights")}
        </Typography>
      </Toolbar>
    </AppFooter>
  );
};

export default Footer;
