import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { orange, red } from "@mui/material/colors";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useState } from "react";

import Navbar from "./components/shared/Navbar";
import Footer from "./components/shared/Footer";
import MainPage from "./components/pages/main/MainPage";
import ContactPage from "./components/pages/contact/ContactPage";
import PrivacyPage from "./components/pages/privacy/PrivacyPage";
import ScrollToTop from "./components/shared/ScrollToTop";
import { useSelector } from "react-redux";
import { selectThemeIsDark } from "./store/slices/themeSlice";

const App = () => {
  const [showNavbar, setShowNavbar] = useState(true);
  const isDark = useSelector(selectThemeIsDark);

  const getTheme = (light: boolean) => {
    return createTheme({
      palette: {
        mode: light ? "light" : "dark",
        primary: { main: red[700] },
        secondary: { main: orange[700] },
      },
      typography: {
        fontFamily: ["Titillium Web", "sans-serif"].join(","),
      },
    });
  };

  const darkTheme = getTheme(false);
  const lightTheme = getTheme(true);

  return (
    <ThemeProvider theme={isDark ? darkTheme : lightTheme}>
      <CssBaseline />
      <BrowserRouter>
        <Navbar show={showNavbar} />
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={<MainPage setShowNavbar={setShowNavbar} />}
          />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
