import { Divider, styled } from "@mui/material";
import { useEffect, useState } from "react";

import Hero from "./Hero";
import Insights from "./Insights";
import Initials from "./Initials";
import Expertise from "./Expertise";
import Team from "./Team";

const Main = styled("main")({
  overflow: "hidden",
  maxWidth: "100vw",
  padding: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

interface MainPageProps {
  setShowNavbar: (value: boolean) => void;
}

const MainPage = (props: MainPageProps) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition < window.innerHeight * 0.5) {
      props.setShowNavbar(false);
    } else {
      props.setShowNavbar(true);
    }
  }, [props, props.setShowNavbar, scrollPosition]);

  return (
    <Main>
      <Hero />
      <Divider />
      <Insights />
      <Divider />
      <Initials />
      <Divider />
      <Expertise />
      <Divider />
      <Team />
      <Divider />
    </Main>
  );
};

export default MainPage;
