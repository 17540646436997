import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import backend from "i18next-http-backend";
import detector from "i18next-browser-languagedetector";

import en from "./translations/en.json";
import ro from "./translations/ro.json";

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: ["en", "ro"],
    supportedLngs: ["en", "ro"],
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: { ...en },
      ro: { ...ro },
    },
  });
