import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface IThemeState {
  isDark: boolean;
}

const initialState: IThemeState = {
  isDark: window.matchMedia("(prefers-color-scheme: dark)").matches,
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.isDark = !state.isDark;
    },
  },
});

const selectSelf = (state: RootState) => state;
const selectThemeIsDark = createSelector(
  selectSelf,
  (state) => state.theme.isDark
);

export const { toggleTheme } = themeSlice.actions;
export { selectThemeIsDark };
export default themeSlice.reducer;
