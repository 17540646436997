import { Card, CardContent, Typography, styled, useTheme } from "@mui/material";
import { useRef } from "react";
import { Variants, motion } from "framer-motion";

import { useTranslation } from "react-i18next";

const InsightsSection = styled("div")(({ theme }) => ({
  padding: "5% 10% 20% 10%",
  maxWidth: "100vw",
  background: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
  position: "relative",
}));

const InsightsCard = styled(motion.div)({
  zIndex: 10,
  width: "clamp(200px, 50%, 800px)",
});

const InsightsCode = styled(motion.div)({
  position: "absolute",
  overflow: "hidden",
  zIndex: 0,
  paddingTop: "5%",
  paddingBottom: "5%",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const Insights = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const insightsRef = useRef(null);

  const scrollVariants: Variants = {
    offscreen: {
      scale: 0.8,
      opacity: 0,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 1,
        ease: "easeOut",
      },
    },
  };

  const generateSlideTextVariants = (
    duration: number,
    delay: number,
    repeatDelay: number,
    orientation: "left" | "right"
  ) => {
    const slideTextVariant: Variants = {
      offscreen: {
        x: orientation === "left" ? -800 : window.innerWidth,
      },
      onscreen: {
        x: orientation === "left" ? window.innerWidth : -800,
        transition: {
          duration: duration,
          delay: delay,
          ease: "linear",
          repeat: Infinity,
          repeatDelay: repeatDelay,
        },
      },
    };
    return slideTextVariant;
  };

  const insightsCodeText = [
    {
      text: 'import { useEffect, useState } from "react";',
      orientation: "left",
    },
    {
      text: "using System.Linq;",
      orientation: "left",
    },
    {
      text: "import { useDispatch, useSelector } from 'react-redux'",
      orientation: "right",
    },
    {
      text: "import { NgModule } from '@angular/core';",
      orientation: "right",
    },
    {
      text: "using System.Threading.Tasks;",
      orientation: "left",
    },
    {
      text: "import { render, screen } from '@testing-library/react';",
      orientation: "right",
    },
    {
      text: "using System.Collections.Generic;",
      orientation: "left",
    },
    {
      text: "import { Injectable } from '@angular/core';",
      orientation: "right",
    },
    {
      text: "using Microsoft.EntityFrameworkCore;",
      orientation: "left",
    },
    {
      text: "import { BrowserModule } from '@angular/platform-browser';",
      orientation: "right",
    },
  ];

  return (
    <InsightsSection ref={insightsRef}>
      <Typography
        component={motion.div}
        initial="offscreen"
        whileInView="onscreen"
        variants={scrollVariants}
        sx={{
          userSelect: "none",
          typography: { md: "h2", sm: "h3", xs: "h4" },
        }}
        gutterBottom
      >
        {t("main.insights.title")}
      </Typography>
      <Card
        component={InsightsCard}
        raised={true}
        initial="offscreen"
        whileInView="onscreen"
        variants={scrollVariants}
        sx={{ position: "relative", zIndex: 10 }}
      >
        <CardContent>
          <Typography
            sx={{
              display: "flex",
              flexWrap: "wrap",
              userSelect: "none",
              typography: { md: "h5", sm: "h6", xs: "subtitle1" },
            }}
          >
            {t("main.insights.text")}
          </Typography>
        </CardContent>
      </Card>
      <InsightsCode>
        {insightsCodeText.map((value, index) => (
          <Typography
            component={motion.div}
            key={index}
            initial="offscreen"
            animate={"onscreen"}
            viewport={{ once: true, amount: 0.8 }}
            variants={generateSlideTextVariants(
              7,
              index,
              2 + (index % 3),
              value.orientation as "left" | "right"
            )}
            color={theme.palette.primary.main}
            sx={{
              userSelect: "none",
              typography: { md: "h6", sm: "subtitle1", xs: "subtitle2" },
            }}
            gutterBottom
          >
            {value.text}
          </Typography>
        ))}
      </InsightsCode>
    </InsightsSection>
  );
};

export default Insights;
