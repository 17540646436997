import {
  Card,
  CardContent,
  Typography,
  styled,
  Grid,
  Tooltip,
} from "@mui/material";
import { Variants, motion } from "framer-motion";

import React from "../../../resources/technologies/react.svg";
import Angular from "../../../resources/technologies/angular.svg";
import Javascript from "../../../resources/technologies/javascript.svg";
import Typescript from "../../../resources/technologies/typescript.svg";
import Html from "../../../resources/technologies/html.svg";
import Css from "../../../resources/technologies/css.svg";

import Csharp from "../../../resources/technologies/csharp.svg";
import Java from "../../../resources/technologies/java.svg";
import Python from "../../../resources/technologies/python.svg";
import Netcore from "../../../resources/technologies/netcore.svg";
import Nodejs from "../../../resources/technologies/nodejs.svg";
import Nestjs from "../../../resources/technologies/nestjs.svg";

import Azure from "../../../resources/technologies/azure.svg";
import Sqlserver from "../../../resources/technologies/sqlserver.svg";
import Mongodb from "../../../resources/technologies/mongodb.svg";
import Mysql from "../../../resources/technologies/mysql.svg";
import Postgresql from "../../../resources/technologies/postgresql.svg";
import { useTranslation } from "react-i18next";

const ExpertiseSection = styled("div")(({ theme }) => ({
  padding: "5% 10% 15% 10%",
  maxWidth: "100vw",
  background: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
}));

const ExpertiseSectionTextCard = styled(Card)(({ theme }) => ({
  marginBottom: "5rem",
  width: "clamp(200px, 50%, 800px)",
}));

const ExpertiseSectionCard = styled(Card)(({ theme }) => ({
  padding: "1.5rem 1.5rem 0.5rem 1.5rem",
  borderRadius: "2rem",
  marginBottom: "2rem",
}));

const ExpertiseSectionIcon = styled(motion.img)(({ theme }) => ({
  width: "100%",
  userSelect: "none",
  [theme.breakpoints.up("md")]: {
    padding: "0.5rem",
  },
  [theme.breakpoints.up("xl")]: {
    padding: "1rem",
  },
}));

const Expertise = () => {
  const { t } = useTranslation();

  const scrollVariants: Variants = {
    offscreen: {
      scale: 0.8,
      opacity: 0,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 1,
        ease: "easeOut",
      },
    },
  };

  const sections = [
    {
      title: t("main.expertise.frontEnd"),
      align: "left",
      data: [
        {
          svg: React,
          title: "React & React Native",
          slots: 1,
        },
        {
          svg: Angular,
          title: "Angular",
          slots: 1,
        },
        {
          svg: Javascript,
          title: "Javascript",
          slots: 1,
        },
        {
          svg: Typescript,
          title: "Typescript",
          slots: 1,
        },
        {
          svg: Html,
          title: "HTML5",
          slots: 1,
        },
        {
          svg: Css,
          title: "CSS3",
          slots: 1,
        },
      ],
    },
    {
      title: t("main.expertise.backEnd"),
      align: "right",
      data: [
        {
          svg: Netcore,
          title: ".NET Core",
          slots: 1,
        },
        {
          svg: Csharp,
          title: "C#",
          slots: 1,
        },
        {
          svg: Nodejs,
          title: "Node.js",
          slots: 1,
        },
        {
          svg: Nestjs,
          title: "Nest.js",
          slots: 1,
        },

        {
          svg: Java,
          title: "Java",
          slots: 1,
        },
        {
          svg: Python,
          title: "Python",
          slots: 1,
        },
      ],
    },
    {
      title: t("main.expertise.cloudAndDatabases"),
      align: "left",
      data: [
        {
          svg: Azure,
          title: "Microsoft Azure",
          slots: 2,
        },
        {
          svg: Sqlserver,
          title: "SQL Server",
          slots: 1,
        },
        {
          svg: Mongodb,
          title: "MongoDB",
          slots: 1,
        },
        {
          svg: Postgresql,
          title: "PostgreSQL",
          slots: 1,
        },
        {
          svg: Mysql,
          title: "MySQL",
          slots: 1,
        },
      ],
    },
  ];

  return (
    <ExpertiseSection>
      <Typography
        component={motion.div}
        initial="offscreen"
        whileInView="onscreen"
        variants={scrollVariants}
        sx={{
          userSelect: "none",
          typography: { md: "h2", sm: "h3", xs: "h4" },
        }}
        gutterBottom
      >
        {t("main.expertise.title")}
      </Typography>
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        variants={scrollVariants}
      >
        <ExpertiseSectionTextCard raised>
          <CardContent>
            <Typography
              sx={{
                display: "flex",
                flexWrap: "wrap",
                userSelect: "none",
                typography: { md: "h5", sm: "h6", xs: "subtitle1" },
              }}
            >
              {t("main.expertise.text")}
            </Typography>
          </CardContent>
        </ExpertiseSectionTextCard>
      </motion.div>
      {sections.map((section, sectionIndex) => {
        return (
          <div key={sectionIndex}>
            <Typography
              component={motion.div}
              initial="offscreen"
              whileInView="onscreen"
              variants={scrollVariants}
              align={section.align as "right" | "left"}
              sx={{
                userSelect: "none",
                typography: { md: "h3", sm: "h4", xs: "h5" },
              }}
              gutterBottom
            >
              {section.title}
            </Typography>
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              variants={scrollVariants}
            >
              <ExpertiseSectionCard raised>
                <CardContent>
                  <Grid container spacing={2}>
                    {section.data.map((item, itemIndex) => (
                      <Grid
                        key={itemIndex}
                        item
                        xs={4 * item.slots}
                        md={2 * item.slots}
                      >
                        <Tooltip
                          title={
                            <Typography
                              sx={{
                                userSelect: "none",
                                typography: { md: "h5", sm: "h6" },
                              }}
                            >
                              {item.title}
                            </Typography>
                          }
                          describeChild
                          enterTouchDelay={0}
                        >
                          <ExpertiseSectionIcon
                            initial={{ scale: 1 }}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            src={item.svg}
                            alt={item.title}
                          />
                        </Tooltip>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </ExpertiseSectionCard>
            </motion.div>
          </div>
        );
      })}
    </ExpertiseSection>
  );
};

export default Expertise;
