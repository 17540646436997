import {
  Drawer,
  List,
  ListItem,
  IconButton,
  Typography,
  useTheme,
  styled,
  Switch,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  closeDrawer,
  openDrawer,
  selectDrawer,
} from "../../store/slices/drawerSlice";
import { selectThemeIsDark, toggleTheme } from "../../store/slices/themeSlice";

const NavDrawerHeader = styled(ListItem)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const NavDrawerOptionSection = styled(ListItem)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const NavDrawerThemeSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.primary.main
        : theme.palette.text.primary,
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    borderRadius: 20 / 2,
  },
}));

const SettingsDrawer = () => {
  const { t, i18n } = useTranslation();
  const drawer = useSelector(selectDrawer);
  const isDark = useSelector(selectThemeIsDark);
  const theme = useTheme();
  const dispatch = useDispatch();

  const languages = [
    {
      value: "en",
      nativeName: "English",
    },
    {
      value: "ro",
      nativeName: "Română",
    },
  ];

  const handleCloseDrawer = () => {
    dispatch(closeDrawer());
  };

  const handleOpenDrawer = () => {
    dispatch(openDrawer("settings"));
  };

  return (
    <>
      <Drawer open={drawer.isOpen} anchor={"right"} onClose={handleCloseDrawer}>
        <List sx={{ width: "clamp(320px, 40vw, 500px)" }}>
          <NavDrawerHeader>
            <Typography
              align="center"
              sx={{ userSelect: "none", typography: { sm: "h5", xs: "h6" } }}
              noWrap
              gutterBottom
            >
              {t("general.menu.title")}
            </Typography>
            <IconButton onClick={handleCloseDrawer} size="large">
              <CloseIcon />
            </IconButton>
          </NavDrawerHeader>
          <NavDrawerOptionSection>
            <Typography
              align="center"
              sx={{
                userSelect: "none",
                typography: { sm: "subtitle1", xs: "caption" },
                color: theme.palette.text.secondary,
              }}
              noWrap
              gutterBottom
            >
              {t("general.theme")}
            </Typography>
            <NavDrawerThemeSwitch
              checked={isDark}
              onChange={() => dispatch(toggleTheme())}
            />
          </NavDrawerOptionSection>
          <NavDrawerOptionSection>
            <Typography
              align="center"
              sx={{
                userSelect: "none",
                typography: { sm: "subtitle1", xs: "caption" },
                color: theme.palette.text.secondary,
              }}
              noWrap
              gutterBottom
            >
              {t("general.language")}
            </Typography>
            <Select
              value={i18n.resolvedLanguage}
              onChange={(event: SelectChangeEvent) => {
                i18n.changeLanguage(event.target.value);
              }}
            >
              {languages.map((lang, index) => (
                <MenuItem value={lang.value} key={index}>
                  {lang.nativeName}
                </MenuItem>
              ))}
            </Select>
          </NavDrawerOptionSection>
        </List>
      </Drawer>
      <IconButton sx={{ ml: 2 }} onClick={handleOpenDrawer}>
        <MenuIcon />
      </IconButton>
    </>
  );
};

export default SettingsDrawer;
