import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Link,
  Typography,
  styled,
} from "@mui/material";
import { ExpandMoreOutlined, InfoOutlined } from "@mui/icons-material";
import { Variants, motion } from "framer-motion";
import { Trans, useTranslation } from "react-i18next";

import config from "../../../config/config.json";
import { useNavigate } from "react-router-dom";

const Main = styled("main")({
  maxWidth: "100vw",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const PrivacyPolicy = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "clamp(15rem, 80%, 50rem)",
});

const PrivacyPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const scrollVariants: Variants = {
    offscreen: {
      scale: 0.8,
      opacity: 0,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  const addAnimation = () => ({
    component: motion.div,
    initial: "offscreen",
    whileInView: "onscreen",
    variants: scrollVariants,
  });

  return (
    <Main>
      <PrivacyPolicy>
        <Typography
          {...addAnimation()}
          sx={{
            mb: 3,
            userSelect: "none",
            typography: { md: "h2", sm: "h3", xs: "h4" },
          }}
          gutterBottom
        >
          {t("privacy.title")}
        </Typography>
        <Alert
          {...addAnimation()}
          severity="error"
          variant="filled"
          icon={<InfoOutlined />}
          sx={{ userSelect: "none", marginBottom: "1rem" }}
        >
          <AlertTitle>{t("privacy.generalConsiderations.title")}</AlertTitle>
          <Trans
            t={t}
            i18nKey={"privacy.generalConsiderations.text"}
            defaults={t("privacy.generalConsiderations.text")}
            values={{
              websiteUrl: config.websiteUrl,
            }}
            components={[
              <Link color="textSecondary" onClick={() => navigate("/")} />,
            ]}
          />
        </Alert>
        <Accordion {...addAnimation()} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <Typography
              sx={{
                userSelect: "none",
                typography: { md: "h4", xs: "h5" },
              }}
            >
              {t("privacy.typesOfInformationCollected.title")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              {...addAnimation()}
              sx={{
                userSelect: "none",
                typography: { sm: "body1", xs: "body2" },
              }}
            >
              <Trans
                t={t}
                i18nKey={"privacy.typesOfInformationCollected.text"}
                defaults={t("privacy.typesOfInformationCollected.text")}
                values={{
                  websiteUrl: config.websiteUrl,
                }}
                components={[<Link onClick={() => navigate("/")} />]}
              />
            </Typography>
            <ol>
              <li>
                <Typography
                  {...addAnimation()}
                  sx={{
                    mt: 3,
                    userSelect: "none",
                    typography: { md: "h5", xs: "h6" },
                  }}
                >
                  {t(
                    "privacy.typesOfInformationCollected.personalInformation.title"
                  )}
                </Typography>
                <Typography
                  {...addAnimation()}
                  sx={{
                    mt: 1,
                    userSelect: "none",
                    typography: { sm: "body1", xs: "body2" },
                  }}
                >
                  <Trans
                    t={t}
                    i18nKey={
                      "privacy.typesOfInformationCollected.personalInformation.text"
                    }
                    defaults={t(
                      "privacy.typesOfInformationCollected.personalInformation.text"
                    )}
                    components={[
                      <ul>
                        {(
                          t(
                            "privacy.typesOfInformationCollected.personalInformation.list",
                            { returnObjects: true }
                          ) as string[]
                        ).map((value, index) => (
                          <li key={index}>{value}</li>
                        ))}
                      </ul>,
                    ]}
                  />
                </Typography>
              </li>
              <li>
                <Typography
                  {...addAnimation()}
                  sx={{
                    mt: 3,
                    userSelect: "none",
                    typography: { md: "h5", xs: "h6" },
                  }}
                >
                  {t(
                    "privacy.typesOfInformationCollected.automaticallyCollectedInformation.title"
                  )}
                </Typography>
                <Typography
                  {...addAnimation()}
                  sx={{
                    mt: 1,
                    userSelect: "none",
                    typography: { sm: "body1", xs: "body2" },
                  }}
                >
                  <Trans
                    t={t}
                    i18nKey={
                      "privacy.typesOfInformationCollected.automaticallyCollectedInformation.text"
                    }
                    defaults={t(
                      "privacy.typesOfInformationCollected.automaticallyCollectedInformation.text"
                    )}
                    values={{
                      websiteUrl: config.websiteUrl,
                    }}
                    components={[<Link onClick={() => navigate("/")} />]}
                  />
                </Typography>
              </li>
            </ol>
          </AccordionDetails>
        </Accordion>
        <Accordion {...addAnimation()} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <Typography
              {...addAnimation()}
              sx={{
                userSelect: "none",
                typography: { md: "h4", xs: "h5" },
              }}
            >
              {t("privacy.sensitivePersonalInformation.title")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              {...addAnimation()}
              sx={{
                userSelect: "none",
                typography: { sm: "body1", xs: "body2" },
              }}
            >
              <Trans
                t={t}
                i18nKey={"privacy.sensitivePersonalInformation.text"}
                defaults={t("privacy.sensitivePersonalInformation.text")}
                values={{
                  websiteUrl: config.websiteUrl,
                }}
                components={[<Link onClick={() => navigate("/")} />]}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion {...addAnimation()} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <Typography
              {...addAnimation()}
              sx={{
                userSelect: "none",
                typography: { md: "h4", xs: "h5" },
              }}
            >
              {t("privacy.howInformationIsUsed.title")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              {...addAnimation()}
              sx={{
                userSelect: "none",
                typography: { sm: "body1", xs: "body2" },
              }}
            >
              <Trans
                t={t}
                i18nKey={"privacy.howInformationIsUsed.text"}
                defaults={t("privacy.howInformationIsUsed.text")}
                components={[
                  <ul>
                    {(
                      t("privacy.howInformationIsUsed.list", {
                        returnObjects: true,
                      }) as string[]
                    ).map((value, index) => (
                      <li key={index}>{value}</li>
                    ))}
                  </ul>,
                ]}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion {...addAnimation()} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <Typography
              {...addAnimation()}
              sx={{
                userSelect: "none",
                typography: { md: "h4", xs: "h5" },
              }}
            >
              {t("privacy.lengthOfTime.title")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              {...addAnimation()}
              sx={{
                userSelect: "none",
                typography: { sm: "body1", xs: "body2" },
              }}
            >
              {t("privacy.lengthOfTime.text")}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion {...addAnimation()} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <Typography
              {...addAnimation()}
              sx={{
                userSelect: "none",
                typography: { md: "h4", xs: "h5" },
              }}
            >
              {t("privacy.whoInformationIsSharedWith.title")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              {...addAnimation()}
              sx={{
                userSelect: "none",
                typography: { sm: "body1", xs: "body2" },
              }}
            >
              <Trans
                t={t}
                i18nKey={"privacy.whoInformationIsSharedWith.text"}
                defaults={t("privacy.whoInformationIsSharedWith.text")}
                components={[
                  <ul>
                    {(
                      t("privacy.whoInformationIsSharedWith.list", {
                        returnObjects: true,
                      }) as string[]
                    ).map((value, index) => (
                      <li key={index}>{value}</li>
                    ))}
                  </ul>,
                ]}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion {...addAnimation()} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <Typography
              {...addAnimation()}
              sx={{
                userSelect: "none",
                typography: { md: "h4", xs: "h5" },
              }}
            >
              {t("privacy.informationSecurity.title")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              {...addAnimation()}
              sx={{
                userSelect: "none",
                typography: { sm: "body1", xs: "body2" },
              }}
            >
              <Trans
                t={t}
                i18nKey={"privacy.informationSecurity.text"}
                defaults={t("privacy.informationSecurity.text")}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion {...addAnimation()} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <Typography
              {...addAnimation()}
              sx={{
                userSelect: "none",
                typography: { md: "h4", xs: "h5" },
              }}
            >
              {t("privacy.declineProvidingPersonalInformation.title")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              {...addAnimation()}
              sx={{
                userSelect: "none",
                typography: { sm: "body1", xs: "body2" },
              }}
            >
              <Trans
                t={t}
                i18nKey={"privacy.declineProvidingPersonalInformation.text"}
                defaults={t("privacy.declineProvidingPersonalInformation.text")}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion {...addAnimation()} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <Typography
              {...addAnimation()}
              sx={{
                userSelect: "none",
                typography: { md: "h4", xs: "h5" },
              }}
            >
              {t("privacy.additionalQuestions.title")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              {...addAnimation()}
              sx={{
                userSelect: "none",
                typography: { sm: "body1", xs: "body2" },
              }}
            >
              <Trans
                t={t}
                i18nKey={"privacy.additionalQuestions.text"}
                defaults={t("privacy.additionalQuestions.text")}
                values={{ emailContact: config.emailSupport }}
                components={[
                  <Link
                    href={`mailto:${config.emailSupport}?Subject=Contact`}
                  />,
                ]}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion {...addAnimation()} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <Typography
              {...addAnimation()}
              sx={{
                userSelect: "none",
                typography: { md: "h4", xs: "h5" },
              }}
            >
              {t("privacy.policyUpdate.title")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              {...addAnimation()}
              sx={{
                userSelect: "none",
                typography: { sm: "body1", xs: "body2" },
              }}
            >
              <Trans
                t={t}
                i18nKey={"privacy.policyUpdate.text"}
                defaults={t("privacy.policyUpdate.text")}
                values={{
                  lastUpdated: new Date(config.privacyPolicyLastUpdated),
                  formatParams: {
                    lastUpdated: {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    },
                  },
                }}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>
      </PrivacyPolicy>
    </Main>
  );
};

export default PrivacyPage;
