import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface IDrawerState {
  isOpen: boolean;
  type: "none" | "settings";
}

const initialState: IDrawerState = {
  isOpen: false,
  type: "none",
};

export const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    closeDrawer: (state) => {
      state.isOpen = false;
      state.type = "none";
    },
    openDrawer: (state, action: PayloadAction<"settings">) => {
      state.isOpen = true;
      state.type = action.payload;
    },
  },
});

const selectSelf = (state: RootState) => state;
const selectDrawer = createSelector(selectSelf, (state) => state.drawer);

export const { closeDrawer, openDrawer } = drawerSlice.actions;
export { selectDrawer };
export default drawerSlice.reducer;
