import { ButtonBase, Typography, styled, useTheme } from "@mui/material";
import { motion, useAnimate } from "framer-motion";
import { useEffect } from "react";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { useTranslation } from "react-i18next";

const HeroCanvas = styled("div")(({ theme }) => ({
  minHeight: "100vh",
  background: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
}));

const LogoBackground = styled(motion.svg)({
  height: "100vh",
  width: "100vw",
  position: "absolute",
  zIndex: 0,
});

const LogoSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  maxHeight: "80%",
  zIndex: 10,
}));

const Logo = styled(motion.svg)({
  width: "clamp(100px, min(50vh, 50vw), 250px)",
  marginTop: "10vh",
  marginBottom: "2rem",
});

const ForwardButton = styled(motion.div)(({ theme }) => ({
  position: "relative",
  zIndex: 10,
  marginTop: "calc(100vh - 100px)",
  width: "100%",
  height: "100px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Hero = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [logoScope, animateLogo] = useAnimate();
  const [lineScope, animateLine] = useAnimate();

  const logoAnimation = async () => {
    await animateLogo("path", { pathLength: 0, offsetDistance: 0 });
    await animateLogo(
      "path",
      { pathLength: 1, fill: "none", fillOpacity: 0, offsetDistance: "100%" },
      { duration: 2, ease: "easeInOut" }
    );
    await animateLogo(
      "path",
      { fill: theme.palette.primary.main, fillOpacity: 1 },
      { duration: 1, ease: "easeInOut" }
    );
  };

  const lineAnimation = async () => {
    for (let i = 1; i <= 15; i++) {
      await animateLine(`path.path${i}`, {
        stroke: theme.palette.primary.main,
        pathLength: 0,
        pathOffset: 0,
      });
    }
    for (let i = 1; i <= 15; i++) {
      animateLine(
        `path.path${i}`,
        { pathLength: 0.2, pathOffset: 1 },
        {
          duration: 5,
          delay: 2 + i,
          repeatDelay: 3 + (i % 3),
          repeat: Infinity,
        }
      );
    }
  };

  const onForwardButtonClick = () => {
    window.scrollTo({ behavior: "smooth", top: window.innerHeight });
  };

  useEffect(() => {
    logoAnimation();
    lineAnimation();
  }, []);

  const backgroundLines = [
    "M2000 197.5C1017.49 29.1739 544.799 -0.0198364 1 197.5",
    "M3.5 732C3.5 732 1113 895 1230.5 527C1348 159 1822 -1 1822 -1",
    "M3.5 511C3.5 511 754.5 1122.5 1111 849.5C1467.5 576.5 1998 732 1998 732",
    "M87 3.5C87 3.5 -234 646.5 842.5 585.5C1919 524.5 1919 1000.5 1919 1000.5",
    "M2000 366.5C895.481 325.234 453.032 438.447 -1 899",
    "M100.5 996C100.5 996 -39.4999 432 644 195C1327.5 -42.0001 2002.5 888 2002.5 888",
    "M-1 346.5C-1 346.5 335 474.5 1061.5 265C1788 55.5 1998 624 1998 624",
    "M4 439C4 439 90 230 560.5 151C1031 72 1019 580 1443 509.5C1867 439 1997.5 98 1997.5 98",
    "M2.5 646C2.5 646 208.5 1047.5 395 913C581.5 778.5 1243.5 678.5 1546.5 863C1849.5 1047.5 1946 -2 1946 -2",
    "M-1 75.5004C-1 75.5004 626 10.0004 922.5 508C1219 1006 1284.5 984.001 1386 944C1487.5 904 1999.5 453 1999.5 453",
    "M0.5 959.5C0.5 959.5 798.5 1056 690 673.5C581.5 291 1367.5 281 1334.5 132.5C1301.5 -16 2001 22 2001 22",
    "M0.5 266.5C0.5 266.5 340.5 910.5 821 680C1301.5 449.5 2001 396 2001 396",
    "M4 135.5C4 135.5 650 1145 1101.5 957.5C1553 770 2001 671.5 2001 671.5",
    "M0.5 587C0.5 587 602 1235.5 903.5 835.5C1205 435.5 1552.5 323 1565 587C1577.5 851 1999.5 935 1999.5 935",
    "M0.5 799C0.5 799 939.5 556.5 791.5 253C643.5 -50.5 1648 513.5 1737.5 241C1827 -31.5 1999.5 303 1999.5 303",
  ];

  const logoLines = [
    "M0.624512 78C0.624512 62.0709 4.20853 51.7799 13.7525 45.1067C23.2766 38.4476 38.8234 35.3178 63.0003 34.0333L59.7132 49.5296C43.5147 50.7622 33.2417 52.7643 27.0208 57.0095C20.6736 61.3408 18.6245 67.9375 18.6245 78C18.6245 85.5703 20.3193 90.991 23.8548 94.8165C27.3827 98.6336 32.6701 100.782 39.6756 101.974C46.6827 103.166 55.4827 103.413 66.1066 103.369C70.5063 103.35 75.2119 103.282 80.2364 103.209C87.2647 103.107 94.9169 102.996 103.228 103L106.993 119C99.141 119.004 91.7296 119.085 84.7513 119.161C76.0562 119.255 68.0336 119.343 60.6688 119.266C47.2436 119.125 36.0896 118.437 27.1805 116.264C18.2805 114.093 11.6693 110.452 7.27024 104.439C2.86546 98.418 0.624512 89.9452 0.624512 78Z",
    "M133.518 120.032L129.283 104.035C144.513 104.853 156.142 107.124 164 113.113C171.934 119.159 176.125 129.081 176.125 145.5C176.125 156.724 174.75 165.116 171.114 171.406C167.491 177.673 161.583 181.922 152.352 184.772C143.105 187.627 130.566 189.063 113.746 189.719C97.0635 190.37 76.2112 190.252 50.2444 190.006L53.5253 175.007L53.9324 175.015L53.9425 175.015C74.1413 175.387 90.6381 175.691 104.015 175.454C117.39 175.217 127.681 174.44 135.456 172.639C143.235 170.836 148.558 167.995 151.922 163.58C155.283 159.17 156.625 153.272 156.625 145.5C156.625 135.002 155.262 128.925 151.534 125.328C149.67 123.529 147.253 122.388 144.227 121.602C141.287 120.838 137.741 120.401 133.518 120.032Z",
    "M30.0416 170.782L26.2676 186.35C16.0144 182.827 9.40011 178.76 5.43784 172.099C1.44043 165.379 0.0864569 155.929 0.60626 141.5H17.6181C17.5208 149.812 17.9399 155.893 19.7374 160.516C21.547 165.17 24.7353 168.305 30.0416 170.782Z",
    "M46.0139 122H61.9926L40.7289 212H23.2666L46.0139 122Z",
    "M51.2618 99.5L75.5168 0.5H98.242L105.971 29H90.0077L86.6073 16.37L85.6363 16.3918L67.2232 99.5H51.2618Z",
    "M123.508 169.957L95.259 52.5H111.739L111.972 53.3907C112.188 54.2151 112.505 55.4246 112.908 56.9646C113.715 60.0446 114.867 64.4469 116.25 69.7358C119.016 80.3138 122.703 94.4386 126.391 108.626C130.078 122.813 133.765 137.062 136.531 147.889C137.913 153.303 139.065 157.86 139.872 161.126C140.275 162.759 140.591 164.067 140.806 164.997C140.914 165.463 140.996 165.831 141.05 166.096C141.077 166.229 141.097 166.333 141.11 166.409C141.121 166.474 141.124 166.502 141.124 166.505C141.122 166.535 141.097 166.639 140.871 166.812C140.636 166.992 140.262 167.182 139.742 167.375C138.707 167.758 137.218 168.106 135.438 168.424C132.007 169.037 127.595 169.523 123.508 169.957Z",
    "M129.256 192.962C135.886 192.557 139.871 192.252 146.752 191.072L151.984 212H134.015L129.256 192.962Z",
    "M167.926 54.5174C170.945 60.6351 172.16 68.4699 171.65 78.5H154.663C155.097 72.1705 154.338 66.9912 152.068 62.8122C149.728 58.5051 145.819 55.3243 140.12 53.033C128.85 48.5027 110.424 47.3925 82.7295 47.9866L85.5399 32.9977C101.134 32.9159 114.446 33.2065 125.615 34.3167C136.885 35.4371 145.941 37.3893 152.942 40.6144C159.93 43.8334 164.867 48.3172 167.926 54.5174Z",
  ];

  return (
    <HeroCanvas>
      <LogoBackground
        ref={lineScope}
        preserveAspectRatio="none"
        viewBox="0 0 2000 1000"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {backgroundLines.map((value, index) => (
          <path
            key={index}
            d={value}
            strokeWidth={3}
            className={`path${index + 1}`}
          />
        ))}
      </LogoBackground>
      <LogoSection>
        <Logo
          ref={logoScope}
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 177 213"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {logoLines.map((value, index) => (
            <path
              key={index}
              d={value}
              stroke={theme.palette.primary.main}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          ))}
        </Logo>
        <Typography
          component={motion.div}
          initial={{ opacity: 0, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 2, duration: 2, ease: "circOut" }}
          sx={{
            userSelect: "none",
            typography: { md: "h1", sm: "h2", xs: "h3" },
          }}
          align="center"
        >
          AEL Software
        </Typography>
        <Typography
          component={motion.div}
          initial={{ opacity: 0, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 3, duration: 2, ease: "circOut" }}
          sx={{
            userSelect: "none",
            typography: { md: "h4", sm: "h5", xs: "h6" },
          }}
          align="center"
          gutterBottom
        >
          {t("general.slogan")}
        </Typography>
      </LogoSection>
      <ButtonBase disableRipple={true} onClick={onForwardButtonClick}>
        <ForwardButton
          initial={{ opacity: 0, y: -200 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 4, duration: 2, ease: "circOut" }}
        >
          <KeyboardDoubleArrowDownIcon
            component={motion.svg}
            sx={{ fontSize: 40 }}
            initial={{ y: -5 }}
            animate={{ y: 5 }}
            transition={{
              delay: 6.5,
              duration: 1,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "easeInOut",
            }}
          />
        </ForwardButton>
      </ButtonBase>
    </HeroCanvas>
  );
};

export default Hero;
