import {
  AppBar,
  Toolbar,
  Typography,
  styled,
  useTheme,
  Button,
  ButtonBase,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { Variants, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Logo from "../../resources/logo.svg";
import SettingsDrawer from "./SettingsDrawer";

const AppNavbar = styled(AppBar)(({ theme }) => ({
  height: "4rem",
  padding: "0 clamp(10px, 5%, 500px)",
  background: theme.palette.background.default,
}));

const AppNavbarLogo = styled(ButtonBase)(({ theme }) => ({
  height: "100%",
  padding: "0 0.5rem 0 0.5rem",
  width: "4rem",
  [theme.breakpoints.up("sm")]: {
    width: "15rem",
  },
}));

const AppNavbarLogoButton = styled("img")({
  height: "60%",
});

const Spacer = styled("span")({
  flex: "1 1 auto",
});

export interface NavbarProps {
  show: boolean;
}

const Navbar = (props: NavbarProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const navbarVariants: Variants = {
    offscreen: {
      opacity: 0,
      y: "-5rem",
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeOut",
        duration: 1,
      },
    },
  };

  return (
    <>
      {props.show && (
        <AppNavbar position="sticky">
          <Toolbar
            component={motion.div}
            initial="offscreen"
            animate={props.show ? "onscreen" : "offscreen"}
            variants={navbarVariants}
            disableGutters
          >
            <Tooltip title={t("general.menu.tooltip")} arrow>
              <AppNavbarLogo
                onClick={() => {
                  setTimeout(() => {
                    navigate("/");
                  }, theme.transitions.duration.shorter);
                }}
              >
                <AppNavbarLogoButton src={Logo} alt="Logo" />
                {matches && (
                  <Typography
                    variant="h5"
                    color="textPrimary"
                    noWrap
                    sx={{ ml: 2 }}
                  >
                    AEL Software
                  </Typography>
                )}
              </AppNavbarLogo>
            </Tooltip>
            <Spacer />
            <Button
              variant="outlined"
              onClick={() => {
                setTimeout(() => {
                  navigate("/contact");
                }, theme.transitions.duration.shorter);
              }}
              sx={{
                mx: 2,
                userSelect: "none",
              }}
            >
              <Typography
                variant={matches ? "subtitle1" : "caption"}
                color="textPrimary"
                noWrap
              >
                {t("contact.label")}
              </Typography>
            </Button>
            <SettingsDrawer />
          </Toolbar>
        </AppNavbar>
      )}
    </>
  );
};

export default Navbar;
